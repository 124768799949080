import styled from 'styled-components';

export default styled.div`
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
    h4 {
        text-align: left;
        margin: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        text-transform: capitalize;
    }
    .search-option-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-auto-rows: minmax(0, auto); /* Allow rows to grow */
        max-height: calc(12 * 50px); /* Adjust 50px to the height of each item */
        overflow-y: auto;
    }
`;
