import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export default styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 0;

    .option {
        display: flex;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        align-items: flex-start;
    }

    .image-wrapper {
        margin-right: 4px;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 18px;
    }

    .option-label {
        margin: auto 0 auto 0px;
        color: ${(props) => props.theme.colors.foreground.base};
        font-family: 'Objektiv Mk2';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        text-align: left;

        ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.SM)} {
            font-size: 12px !important;
        }
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .option {
            width: 100%;
        }

        .option-label {
            text-align: left;
            width: 100%;
        }
    }
`;
