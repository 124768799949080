import React, { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, IconName, IconSizes, ImageSlider } from 'dbi-pearl-ui-kit';
import { VendorLandingPageSliderStyled } from './VendorLandingPageSlider.styled';
import { usePearlSearch } from '../../contexts/PearlSearchContext';
import { ResponsiveTextElement } from '../../../framework';
import { useStore } from '../../../stores/Store';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'shared/constants/navigation';

const generateCategoryImages = (categories, imageCaptions) => {
    const images = [];

    categories.forEach((c) => {
        images.push({
            ...c.image,
            caption: imageCaptions[c.externalId] || '',
        });
    });

    return images;
};

const VendorLandingPageSlider = (props) => {
    const { t } = useTranslation();
    const swiperRef = useRef();
    const handleSwiper = (swiper) => {
        swiperRef.current = swiper;
    };
    const { categories, setPrivateSearchContextCategory, setPrivateLoading, shouldNavigate } =
        props;
    const { setSearchCategory, setLoading } = usePearlSearch();
    const { appData } = useStore();
    const navigate = useNavigate();

    const imageCaptions = t('Categories', { returnObjects: true });
    const [categoryImages] = useState(generateCategoryImages(categories, imageCaptions));

    const onImageClick = useCallback((index) => {
        const cat = appData.getCategoryByExternalId(categories[index].externalId);

        // using a private search context
        if (setPrivateSearchContextCategory) {
            // if category travels, stay on page and start a private search for category
            if (appData.getCategoryTravels(cat)) {
                setPrivateSearchContextCategory(cat);
                setPrivateLoading(true);
            } else {
                //if cateogry does not travel, set primary search context and navigate to Vendor Listing Page
                setSearchCategory(cat);
                navigate(APP_ROUTES.vendors);
            }
        }
        // using primary search context
        else {
            setSearchCategory(cat);
            // go to a VLP page when current page isn't VLP
            if (shouldNavigate) {
                navigate(APP_ROUTES.vendors);
            }
            // or stay on page & load a new search
            else {
                setLoading(true);
            }
        }
    }, []);

    return (
        <VendorLandingPageSliderStyled>
            <div className="left-icon">
                <IconButton
                    iconName={IconName.CHEVRON_LEFT}
                    size={IconSizes.MD}
                    withBorder={true}
                    clickHandler={() => swiperRef?.current.slidePrev()}
                />
            </div>
            <div data-testid="vlp-slider" className="vlp-slider-section">
                <ResponsiveTextElement className={'vlp-subtitle'} large={'h3'} small={'h2'}>
                    {t('Pages.VendorLandingPage.Subtitle')}
                </ResponsiveTextElement>
                <ImageSlider
                    images={categoryImages}
                    hideCaptions={true}
                    centeredSlides={false}
                    onImageClick={onImageClick}
                    spaceBetween={75}
                    isBackgroundImage={true}
                    navigation={false}
                    onSwiper={handleSwiper}
                />
            </div>
            <div className="right-icon">
                <IconButton
                    iconName={IconName.CHEVRON_RIGHT}
                    size={IconSizes.MD}
                    withBorder={true}
                    clickHandler={() => swiperRef?.current.slideNext()}
                />
            </div>
        </VendorLandingPageSliderStyled>
    );
};

export { VendorLandingPageSlider };
