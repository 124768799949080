import styled from 'styled-components';

export const SearchContainer = styled.div`
    height: 50px;
    min-width: 327px;
    background-color: ${(props) => props?.theme?.colors?.background?.container};
    border-radius: 25px;
    border: 1px solid ${(props) => props?.theme?.colors?.foreground?.border};
    padding: 6px 6px 6px 20px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 48px);

    .icon-search {
        padding: 12px;
        color: ${(props) => props?.theme?.colors?.primary?.contrast};
        background-color: ${(props) => props?.theme?.colors?.primary?.base};
        border-radius: 50%;
        align-self: center;
        margin-left: 8px;

        &:hover {
            background-color: ${(props) => props?.theme?.colors?.primary?.hover_link};
        }
    }

    @media (max-width: 815px) {
        .icon-search {
            margin-left: 0;
        }
    }
`;

export const Button = styled.button`
    border: unset;
    background-color: unset;
    text-align: left;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    width: 100%;
    font-family: 'Objektiv Mk2 Bold';

    p {
        margin: 0px;
        color: ${(props) => props?.theme?.colors?.foreground?.secondary_text};
    }

    .makeItTruncateActually {
        text-overflow: ellipsis;
        display: block;
        :hover {
            color: ${(props) => props?.theme?.colors?.primary?.base};
            cursor: pointer;
        }
    }
`;

export const VerticalLine = styled.div`
    border-left: 1px solid ${(props) => props?.theme?.colors?.foreground?.border};
    align-self: center;
    height: 24px;
`;

export const SearchIcon = styled.div`
    height: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    button {
        padding: 0;
    }
`;
export const LocationDrawerWrapper = styled.div`
    .search-option-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important; /* Two columns */
        grid-auto-rows: minmax(0, auto); /* Allow rows to grow */
        max-height: calc(12 * 50px); /* Adjust 50px to the height of each item */
        overflow-y: auto;
    }
`;
