import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

export default styled.div`
    background-color: ${(props) => props.theme.colors.primary.contrast};
    border-radius: 18px;
    border: 1px solid var(--Main-Brand-Brand-NEW-Lavender-Pink, #f6adcd);
    padding: 16px;
    margin-top: 8px;
    align-items: flex-start;
    gap: 12px;
    display: flex;
    flex-direction: column;
    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.SM)} {
        padding: 16px 5px;
    }

    .search-box {
        background-color: ${(props) => props.theme.colors.disabled.base};
        display: flex;
        padding: 12px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 208px;

        button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;
            color: #717777;
        }

        input {
            border: none;
            flex-grow: 2;
            padding: 0;
            background-color: ${(props) => props.theme.colors.disabled.base};
            color: black;
        }
    }

    .googleLogo {
        position: absolute;
        bottom: 0;
    }
`;
