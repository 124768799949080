import SearchDrawer from '../SearchDrawer/SearchDrawer';
import { loadAutocompleteService, useOnChangeDebounce } from 'framework';
import { GeocodeService } from '../../../../lib/apis/GeocodeService';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import topCities from '../../../constants/topCities';
import { usePearlSearch } from 'event-user/contexts/PearlSearchContext';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'shared/constants/navigation';

export default function SearchByLocation(props) {
    const { active } = props;

    const inputRef = useRef();
    const { t } = useTranslation();
    const { setSearchLocation } = usePearlSearch();
    const navigate = useNavigate();
    const { debounceChangeHandler } = useOnChangeDebounce(400);
    const [searchOptions, setSearchOptions] = useState([
        {
            header: t('SearchDrawer.TopCities'),
            options: topCities.map((city) => ({
                displayText: city.label,
                latitude: city.latitude,
                longitude: city.longitude,
            })),
        },
    ]);
    const [searchByGoogle, setSearchByGoogle] = useState(false);

    const geocodeApi = GeocodeService();

    const displaySuggestions = function (predictions, status) {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            setSearchOptions([{ options: [] }]);
            return;
        }

        setSearchByGoogle(false);
        setSearchOptions([
            {
                options: predictions.map((prediction) => ({
                    displayText: prediction.description.split(', USA').slice(0, -1)[0],
                    placeId: prediction.place_id,
                })),
            },
        ]);
    };

    const onInputChange = (event) => {
        debounceChangeHandler(event, inputRef, (searchValue) =>
            loadAutocompleteService(searchValue, displaySuggestions, {
                scriptId: 'location-search-drawer-autocomplete',
            })
        );
    };

    const setLocationSearchValue = async (selectedLocation) => {
        if (selectedLocation.latitude && selectedLocation.longitude) {
            setSearchLocation(selectedLocation);
        } else if (selectedLocation.placeId) {
            const coordinates = await geocodeApi.getGeocodeByPlaceId(selectedLocation.placeId);
            setSearchLocation({ ...selectedLocation, ...coordinates });
        }
        navigate(APP_ROUTES.vendors);
    };

    return (
        <SearchDrawer
            searchOptionSections={searchOptions}
            searchPlaceholder={t('SearchDrawer.LocationPlaceholder')}
            onInputChange={onInputChange}
            onSearch={setLocationSearchValue}
            active={active}
            displayGoogleLogo={searchByGoogle}
            displaySearchInput={true}
            mapMarker={true}
        />
    );
}
