import PropTypes from 'prop-types';
import SearchOptionListStyled from './SearchOptionList.styled';
import { PearlIcon, IconName } from 'dbi-pearl-ui-kit';

const Option = (props) => {
    const { option, handleClick, optionType, mapMarker } = props;

    const label = option.displayText;
    const defaultOptionType = optionType ? optionType : 'submit';
    return (
        <button
            className="option"
            data-testid="option"
            aria-describedby={label}
            onClick={() => handleClick(option)}
            type={defaultOptionType}
        >
            {!!mapMarker && (
                <div className="image-wrapper" data-testid="map-icon">
                    <PearlIcon size={'18'} iconName={IconName.MAP_MARKER_OUTLINE} />
                </div>
            )}
            <div className="option-label">{label}</div>
        </button>
    );
};

export default function SearchOptionList(props) {
    const { options, updateSearch, optionType, mapMarker } = props;

    return (
        <SearchOptionListStyled className="search-option-container">
            {options.map((option, index) => (
                <div key={index}>
                    <Option
                        option={option}
                        handleClick={updateSearch}
                        optionType={optionType}
                        mapMarker={mapMarker}
                    />
                </div>
            ))}
        </SearchOptionListStyled>
    );
}

SearchOptionList.propTypes = {
    options: PropTypes.array,
    updateSearch: PropTypes.func,
};
