import PropTypes from 'prop-types';
import { PearlIcon, IconName, IconSizes } from 'dbi-pearl-ui-kit';
import { useState } from 'react';
import SearchDrawerStyled from './SearchDrawer.styled';
import SearchOptionSection from '../SearchOptionSection/SearchOptionSection';
import { GoogleLogo } from 'shared/components/GoogleLogo/GoogleLogo';

export default function SearchDrawer(props) {
    const {
        searchPlaceholder,
        searchOptionSections,
        onSearch,
        active,
        onInputChange,
        displayGoogleLogo,
        displaySearchInput,
        mapMarker,
    } = props;
    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
        onInputChange && onInputChange(event);
    };

    return (
        <div id={`${searchPlaceholder}-search-drawer`} data-testid="search-drawer">
            <SearchDrawerStyled active={active}>
                {displaySearchInput && (
                    <div className="search-box">
                        <input
                            data-testid={searchPlaceholder}
                            type="text"
                            placeholder={searchPlaceholder}
                            onInput={handleSearchChange}
                            value={searchValue}
                        />
                        {searchValue && (
                            <button data-testid="submit-button">
                                <PearlIcon iconName={IconName.SEARCH} size={IconSizes.MD} />
                            </button>
                        )}
                    </div>
                )}
                {searchOptionSections &&
                    searchOptionSections.map((section, index) => (
                        <SearchOptionSection
                            header={section.header}
                            options={section.options}
                            setSearchValue={onSearch}
                            key={index}
                            mapMarker={mapMarker}
                        />
                    ))}
                {!!displayGoogleLogo && <GoogleLogo />}
            </SearchDrawerStyled>
        </div>
    );
}

SearchDrawer.propTypes = {
    searchPlaceholder: PropTypes.string,
    searchOptionSections: PropTypes.array,
    searchInputName: PropTypes.string,
    searchFilterFunction: PropTypes.func,
    onSearch: PropTypes.func,
    active: PropTypes.bool,
};
