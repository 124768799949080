import React, { useEffect, useState } from 'react';
import DefaultImage from '../../../../assets/pearl-bg.jpg';
import SearchDrawer from '../../Search/SearchDrawer/SearchDrawer';
import { useStore } from '../../../../stores/Store';
import { useTranslation } from 'react-i18next';

function VendorSearchDrawer(props) {
    const { onSearch, active, displaySearchInput } = props;
    const { t } = useTranslation();
    const { appData } = useStore();
    const [categoryList, setCategoryList] = useState([]);
    const [filteredCategoryList, setFilteredCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getVendorCategoryImages = () => {
        if (appData.categoryList) {
            const categoryOptionsList = appData.categoryList.map((category) => {
                return {
                    displayText: category.name, // todo: translations
                    name: category.name,
                    imageUrl: category.image?.url,
                    id: category.id,
                    externalId: category.externalId,
                    namePlural: category.namePlural,
                };
            });

            setCategoryList(categoryOptionsList);
            setFilteredCategoryList(categoryOptionsList);
        }
    };

    const filterVendorCategories = (inputEvent) => {
        const filterValue = inputEvent.target.value.toLowerCase();

        const filteredCategoryList = [];

        categoryList.forEach((category) => {
            if (category.name.toLowerCase().indexOf(filterValue) > -1) {
                filteredCategoryList.push(category);
            }
        });
        if (filteredCategoryList.length === 0) {
            filteredCategoryList.push({
                displayText: t('SearchDrawer.NoResultsFound'),
                imageUrl: DefaultImage,
            });
        }
        setFilteredCategoryList(filteredCategoryList);
    };

    useEffect(() => {
        /* eslint-disable */
        if (isLoading) {
            getVendorCategoryImages();
            setIsLoading(false);
        }
    }, [isLoading]);

    return (
        <SearchDrawer
            searchOptionSections={[
                {
                    options: filteredCategoryList,
                },
            ]}
            searchPlaceholder={t('SearchDrawer.VendorPlaceholder')}
            onSearch={onSearch}
            active={active}
            onInputChange={filterVendorCategories}
            displaySearchInput={displaySearchInput}
        />
    );
}

export default VendorSearchDrawer;
