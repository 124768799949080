import React, { useState, useEffect } from 'react';
import { IconName, IconSizes, TruncatedTextDisplay, IconButton } from 'dbi-pearl-ui-kit';
import PropTypes from 'prop-types';
import {
    Button,
    SearchContainer,
    SearchIcon,
    VerticalLine,
    LocationDrawerWrapper,
} from './SearchButton.styled';
import { usePearlSearch } from '../../../contexts/PearlSearchContext';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'shared/constants/navigation';
import VendorSearchDrawer from '../VendorSearchDrawer/VendorSearchDrawer';
import SearchByLocation from '../LocationSearchDrawer/LocationSearchDrawer';

export default function SearchButton(props) {
    const { openEmptyDrawersOnload } = props;
    const {
        category,
        setSearchLocation,
        location,
        getSearchPath,
        setSearchCategory,
        filters,
        setLoading,
    } = usePearlSearch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const browserLocation = useLocation();
    const [vendorSearchDrawerOpen, setVendorSearchDrawerOpen] = useState(
        openEmptyDrawersOnload ? !category : false
    );

    const [locationSearchDrawerOpen, setLocationSearchDrawerOpen] = useState(
        openEmptyDrawersOnload ? !vendorSearchDrawerOpen && !location?.displayText : false
    );

    useEffect(() => {
        if (location?.latitude && location?.longitude) {
            setLocationSearchDrawerOpen(false);
        }
        if (category) {
            setVendorSearchDrawerOpen(false);
        }
    }, [location, category]);

    const toggleSearchByVendor = () => {
        setLocationSearchDrawerOpen(false);
        setVendorSearchDrawerOpen(!vendorSearchDrawerOpen);
    };

    const toggleSearchByLocation = () => {
        setVendorSearchDrawerOpen(false);
        setLocationSearchDrawerOpen(!locationSearchDrawerOpen);
    };

    const handleOnCategorySearch = (cat) => {
        const currentPathname = browserLocation.pathname;
        if (currentPathname.includes(APP_ROUTES.nationalTravelVendors)) {
            setSearchCategory(cat);
            navigate(`${APP_ROUTES.nationalTravelVendors}/${cat?.externalId}`);
        } else {
            setSearchCategory(cat);
        }
        setLoading(true);
        toggleSearchByVendor();
    };

    const handleOnLocationSearch = (loc) => {
        if (loc) {
            setSearchLocation(loc);
        }
        if (category) {
            if (browserLocation.pathname.includes(APP_ROUTES.vendors)) {
                setSearchLocation(loc);
            } else {
                navigate(getSearchPath(category, loc, filters));
            }
        } else {
            setSearchLocation(loc);
        }
        setLoading(true);
        toggleSearchByLocation();
    };

    const handleSearchIconClick = () => {
        const currentPathname = browserLocation.pathname;
        if (currentPathname.includes(APP_ROUTES.vendors)) {
            setLoading(true);
        } else {
            if (!!location.latitude) {
                setSearchCategory(category);
                setSearchLocation(location);
                navigate(getSearchPath(category, location, filters));
            } else {
                navigate(`${APP_ROUTES.nationalTravelVendors}/${category?.externalId}`);
            }
        }
    };

    return (
        <>
            <SearchContainer>
                <Button
                    data-testid="left-button"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation(); // Prevent default action
                        toggleSearchByVendor();
                    }}
                >
                    <TruncatedTextDisplay
                        maxWidth={'110px'}
                        lineCount={1}
                        textAlign={'left'}
                        className="makeItTruncateActually"
                    >
                        {category?.name ? (
                            <>{category?.name}</>
                        ) : (
                            <b className="p3Emphasis">{t('SearchButton.VendorTitle')}</b>
                        )}
                    </TruncatedTextDisplay>
                </Button>
                <VerticalLine />
                <Button
                    data-testid="right-button"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation(); // Prevent default action
                        toggleSearchByLocation();
                    }}
                >
                    <TruncatedTextDisplay
                        maxWidth={'110px'}
                        lineCount={1}
                        textAlign={'left'}
                        className="makeItTruncateActually"
                    >
                        {location?.displayText ? (
                            <>{location?.displayText}</>
                        ) : (
                            <div className="p3Emphasis">{t('SearchButton.LocationTitle')}</div>
                        )}
                    </TruncatedTextDisplay>
                </Button>

                <SearchIcon>
                    <IconButton
                        iconName={IconName.SEARCH}
                        ariaLabel={'Click to search for vendors within the category and location'}
                        size={IconSizes.MD}
                        withBorder={false}
                        clickHandler={handleSearchIconClick}
                        data-testid={'search-btn'}
                    />
                </SearchIcon>
            </SearchContainer>
            {vendorSearchDrawerOpen && (
                <div>
                    <VendorSearchDrawer
                        onSearch={handleOnCategorySearch}
                        active={vendorSearchDrawerOpen}
                        displaySearchInput={false}
                    />
                </div>
            )}
            {locationSearchDrawerOpen && (
                <LocationDrawerWrapper>
                    <SearchByLocation
                        onSearch={handleOnLocationSearch}
                        active={locationSearchDrawerOpen}
                    />
                </LocationDrawerWrapper>
            )}
        </>
    );
}

SearchButton.propTypes = {
    leftSideTitle: PropTypes.string,
    leftSideSubtitle: PropTypes.string,
    onLeftSideClick: PropTypes.func,
    rightSideTitle: PropTypes.string,
    rightSideSubtitle: PropTypes.string,
    onRightSideClick: PropTypes.func,
    onSearchClick: PropTypes.func,
};
