import PropTypes from 'prop-types';
import SearchOptionList from '../SearchOptionList/SearchOptionList';
import SearchOptionSectionStyled from './SearchOptionSection.styled';

export default function SearchOptionSection(props) {
    const { header, options, setSearchValue, mapMarker } = props;

    return (
        <SearchOptionSectionStyled>
            {header && <h4>{header}</h4>}
            <SearchOptionList
                options={options}
                updateSearch={setSearchValue}
                mapMarker={mapMarker}
            />
        </SearchOptionSectionStyled>
    );
}

SearchOptionSection.propTypes = {
    header: PropTypes.string,
    options: PropTypes.array,
    setSearchValue: PropTypes.func,
};
