import React from 'react';
import PropTypes from 'prop-types';
import { ImageSlider } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { WebstoreImageSliderStyled } from './WebstoreImageSlider.styled';
import { usePearlModal } from '../../../contexts/PearlModalContext';
import { ModalImageGallery } from '../ModalImageGallery/ModalImageGallery';
import image1 from '../../../../assets/WebstoreDefault/placeholder-image-01.jpg';
import image2 from '../../../../assets/WebstoreDefault/placeholder-image-02.jpg';
import image3 from '../../../../assets/WebstoreDefault/placeholder-image-03.jpg';

const WebstoreImageSlider = (props) => {
    const { webstore, onButtonClick, objectFitValue, onEdit, isVendorPage } = props;
    const { t } = useTranslation();
    const { openModal } = usePearlModal();

    const unFlaggedImages = webstore.Media?.images?.filter(
        (x) => x.validationResult || x.adminApproved
    );

    const onImageClick = (id) => {
        openModal(<ModalImageGallery images={unFlaggedImages} initIndex={id} />);
    };

    const placeholderImages = [
        {
            caption: 'placeholder one',
            url: image1,
            id: 1,
        },
        {
            caption: 'placeholder two',
            url: image2,
            id: 2,
        },
        {
            caption: 'placeholder three',
            url: image3,
            id: 3,
        },
    ];

    return (
        <WebstoreImageSliderStyled>
            <div className="carousel">
                {unFlaggedImages?.length > 0 ? (
                    <ImageSlider
                        images={unFlaggedImages}
                        imageVariant="gallery"
                        buttonLabel={
                            isVendorPage
                                ? t('WebstoreSections.Media.SeeAllMediaButtonLabel')
                                : t('WebstoreSections.Media.EditPhotosLabel')
                        }
                        onButtonClick={isVendorPage ? onButtonClick : onEdit}
                        objectFitValue={objectFitValue}
                        onImageClick={onImageClick}
                        buttonIcon={isVendorPage ? 'grid' : 'edit'}
                    />
                ) : (
                    <ImageSlider
                        images={placeholderImages}
                        onButtonClick={onEdit}
                        buttonLabel={t('WebstoreSections.Media.UploadPhotosLabel')}
                        imageVariant="gallery"
                        objectFitValue="cover"
                        buttonIcon="edit"
                    />
                )}
            </div>
        </WebstoreImageSliderStyled>
    );
};

WebstoreImageSlider.propTypes = {
    webstore: PropTypes.object,
    onButtonClick: PropTypes.func,
    objectFitValue: PropTypes.string,
    isVendorPage: PropTypes.bool,
    onEdit: PropTypes.func,
};

WebstoreImageSlider.defaultProps = {
    objectFitValue: 'cover',
};

export { WebstoreImageSlider };
