import styled from 'styled-components';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';

const VendorLandingPageSliderStyled = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
    padding-bottom: 24px;
    @media (min-width: 1150px) and (max-width: 1500px) {
        margin: 0px 24px;
    }

    .vlp-subtitle {
        align-self: start;
    }

    .vlp-slider-section {
        align-self: center;
        padding: 24px 12px 0px 12px;
    }

    .slide-caption-container {
        position: static;
        text-align: center;
    }

    .swiper-slide {
        max-width: 120px;
        img {
            height: 120px;
            width: 120px;
            cursor: pointer;
        }
    }

    .slide-caption {
        height: 50px;
        flex-wrap: wrap;
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 55px;
    }

    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        width: 100%;
        padding-bottom: 12px;
    }
    .slide {
        object-fit: cover;
    }
    .slide-background-image {
        height: 75px !important;
        width: 130px !important;
        background-color: transparent;
        background-size: cover !important;
        border: none;
        display: flex;
        cursor: pointer;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
        padding: 0px 12px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        color: ${(props) => props.theme.colors.primary.contrast};
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        font-family: 'Objektiv Mk2 Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16.5px; /* 82.5% */
        letter-spacing: 1.5px;
    }
    .slide-text-overlay {
        p {
            text-transform: uppercase;
        }
    }
    .left-icon {
        padding-top: 40px;
        button {
            background: #e0e2e2;
        }
    }
    .right-icon {
        padding-top: 40px;
        button {
            background: #e0e2e2;
        }
    }
`;

export { VendorLandingPageSliderStyled };
