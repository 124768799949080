import BeautyImage from './beauty1.jpg';
import BeautyImageNew from './beauty1.jpg';
import CaterersImage from './catering1.jpg';
import CaterersImageNew from './catering1.jpg';
import FloristsImage from './florist1.jpg';
import FloristsImageNew from './florist1.jpg';
import MusicImage from './bands1.jpg';
import PhotographyImage from './photography1.jpg';
import PhotographyImageNew from './photography1.jpg';
import VenuesImage from './venues1.jpg';
import VenuesImageNew from './venues1.jpg';
import LodgingImage from './lodging1.jpg';
import OfficiantsImage from './officiants.jpg';
import PlanningImage from './planning1.jpg';
import PlanningImageNew from './planning1.jpg';
import RentalEquipmentImage from './rental-equipment1.jpg';
import RentalEquipmentImageNew from './rental-equipment1.jpg';
import InvitationsStationeryImage from './invitations1.jpg';
import FavorsGiftImage from './favors1.jpg';
import TransportationImage from './transportation1.jpg';
import JewelryImage from './jewelry1.jpg';
import EntertainmentImage from './entertainment1.jpg';
import HealthWellnessImage from './health-wellness1.jpg';
import TravelImage from './travel.jpg';
import PhotoboothsImage from './photobooths1.jpg';
import DressesImage from './dresses1.jpg';
import TuxedosImage from './tuxedo1.jpg';
import HomeImage from './home1.jpg';
import VideographyImage from './videography2.jpg';
import VideographyImageNew from './videography2.jpg';
import WeddingCakesImage from './cake1.jpg';
import OtherServicesImage from './OtherServices1.jpg';
import DefaultImage from '../../pearl-bg.jpg';

// todo: translate captions, probably in the caller, because react
// ooooor - vendorCategoryTranslations in the db i think

const DefaultCategoryImage = {
    externalId: 'default',
    url: DefaultImage,
};

const CategoryImages = {
    travel: {
        externalId: 'travel',
        url: TravelImage,
    },
    music: {
        externalId: 'music',
        url: MusicImage,
    },
    beauty: {
        externalId: 'beauty',
        url: BeautyImage,
    },
    foodbeverage: {
        externalId: 'foodbeverage',
        url: CaterersImage,
    },
    bridaltuxedo: {
        externalId: 'bridaltuxedo',
        url: DressesImage,
    },
    entertainmentotherservices: {
        externalId: 'entertainmentotherservices',
        url: EntertainmentImage,
    },
    favorsgifts: {
        externalId: 'favorsgifts',
        url: FavorsGiftImage,
    },
    florist: {
        externalId: 'florist',
        url: FloristsImage,
    },
    healthwellness: {
        externalId: 'healthwellness',
        url: HealthWellnessImage,
    },
    home: {
        externalId: 'home',
        url: HomeImage,
    },
    invitationsstationery: {
        externalId: 'invitationsstationery',
        url: InvitationsStationeryImage,
    },
    jewelry: {
        externalId: 'jewelry',
        url: JewelryImage,
    },
    lodging: {
        externalId: 'lodging',
        url: LodgingImage,
    },
    officiants: {
        externalId: 'officiants',
        url: OfficiantsImage,
    },
    photobooths: {
        externalId: 'photobooths',
        url: PhotoboothsImage,
    },
    photographyvideography: {
        externalId: 'photographyvideography',
        url: PhotographyImage,
    },
    rentalequipment: {
        externalId: 'rentalequipment',
        url: RentalEquipmentImage,
    },
    transportation: {
        externalId: 'transportation',
        url: TransportationImage,
    },
    tuxedos: {
        externalId: 'tuxedos',
        url: TuxedosImage,
    },
    venues: {
        externalId: 'venues',
        url: VenuesImage,
    },
    videography: {
        externalId: 'videography',
        url: VideographyImage,
    },
    weddingcakes: {
        externalId: 'weddingcakes',
        url: WeddingCakesImage,
    },
    planning: {
        externalId: 'planning',
        url: PlanningImage,
    },
    otherservices: {
        externalId: 'otherservices',
        url: OtherServicesImage,
    },
};
const HomeCategoryImages = {
    travel: {
        externalId: 'travel',
        url: TravelImage,
    },
    music: {
        externalId: 'music',
        url: MusicImage,
    },
    beauty: {
        externalId: 'beauty',
        url: BeautyImageNew,
    },
    foodbeverage: {
        externalId: 'foodbeverage',
        url: CaterersImageNew,
    },
    bridaltuxedo: {
        externalId: 'bridaltuxedo',
        url: DressesImage,
    },
    entertainmentotherservices: {
        externalId: 'entertainmentotherservices',
        url: EntertainmentImage,
    },
    favorsgifts: {
        externalId: 'favorsgifts',
        url: FavorsGiftImage,
    },
    florist: {
        externalId: 'florist',
        url: FloristsImageNew,
    },
    healthwellness: {
        externalId: 'healthwellness',
        url: HealthWellnessImage,
    },
    home: {
        externalId: 'home',
        url: HomeImage,
    },
    invitationsstationery: {
        externalId: 'invitationsstationery',
        url: InvitationsStationeryImage,
    },
    jewelry: {
        externalId: 'jewelry',
        url: JewelryImage,
    },
    lodging: {
        externalId: 'lodging',
        url: LodgingImage,
    },
    officiants: {
        externalId: 'officiants',
        url: OfficiantsImage,
    },
    photobooths: {
        externalId: 'photobooths',
        url: PhotoboothsImage,
    },
    photographyvideography: {
        externalId: 'photographyvideography',
        url: PhotographyImageNew,
    },
    rentalequipment: {
        externalId: 'rentalequipment',
        url: RentalEquipmentImageNew,
    },
    transportation: {
        externalId: 'transportation',
        url: TransportationImage,
    },
    tuxedos: {
        externalId: 'tuxedos',
        url: TuxedosImage,
    },
    venues: {
        externalId: 'venues',
        url: VenuesImageNew,
    },
    videography: {
        externalId: 'videography',
        url: VideographyImageNew,
    },
    weddingcakes: {
        externalId: 'weddingcakes',
        url: WeddingCakesImage,
    },
    planning: {
        externalId: 'planning',
        url: PlanningImageNew,
    },
    otherservices: {
        externalId: 'otherservices',
        url: OtherServicesImage,
    },
};
export { CategoryImages, HomeCategoryImages, DefaultCategoryImage };
